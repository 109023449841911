import React, { useContext, useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { Duration } from "luxon";
import { TimeContext, Countdown, Hero, AnalyticsContext } from "Components";
import { Layout, BodySection, Container } from "../Layout";
import styled, { css } from "styled-components";
import { useMediaQuery } from "Components/Hooks/useMediaQuery";

export const PreShow = ({ event }) => {
  const { time } = useContext(TimeContext);
  const { logLeadSubmission } = useContext(AnalyticsContext);

  const isMobile = useMediaQuery();
  const [eventFired, setEventFired] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const utm_source = params.get("utm_source");
  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;
  let patternRepeat = `${api}/o/${storageDirectory}%2Ffooter-pattern.png?alt=media`;
  let patternRepeatMobile = `${api}/o/${storageDirectory}%2Fmobile-pattern.png?alt=media`;

  useEffect(() => {
    if (utm_source === "oauth" && !eventFired) {
      setEventFired(true);
      logLeadSubmission({ formPart: 0 });
    }
    // eslint-disable-next-line
  }, []);

  const timeToStart =
    time < event?.start &&
    Duration.fromMillis(event?.start - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  const timer = {
    label: event?.preShow?.countdownLabel ?? "Show starts in",
    days: timeToStart.days,
    hours: timeToStart.hours,
    minutes: timeToStart.minutes,
    seconds: timeToStart.seconds,
  };

  return (
    <Layout
      // header
      showHeader
      // hero
      heroContent={<Hero heroContent={<Countdown {...timer} />} />}
      // heading
      heading={event?.preShow?.heading}
      subHeading={event.preShow?.subHeading}
      // main
      // noPadding
      // footer
      showFooter
    >
      <BodySection>
        <Container>
          <p>{ReactHtmlParser(event?.preShow?.body)}</p>
        </Container>
      </BodySection>
      <BackgroundPattern isMobile={isMobile}>
        <img
          src={isMobile ? patternRepeatMobile : patternRepeat}
          alt="aarp black history month pattern"
        />
      </BackgroundPattern>
    </Layout>
  );
};
const BackgroundPattern = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  img {
    margin-bottom: -15px;
    width: 100%;
    object-fit: cover;
    margin-left: 0px;
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      img {
        margin-bottom: -2px;
        height: unset;
        width: 170%;
      }
    `}
`;
