import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import { GridColumn, Label, Footnote, LoaderInline } from "notes";
import {
  PhoneMask,
  firebase,
  MessageContext,
  FieldMessage,
  EventContext,
} from "Components";
import { useMediaQuery } from "Components/Hooks/useMediaQuery";

export const TextReminder = ({
  showLabel,
  showFootnote = true,
  buttonText,
}) => {
  const { event } = useContext(EventContext);
  const [cookies, setCookie] = useCookies();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const defaultNumber = urlParams.get("phone");

  let userToken = urlParams.get("token");
  if (!userToken) userToken = cookies.token;

  const [loading, setLoading] = useState(false);
  const { displayMessage } = useContext(MessageContext);
  const [phoneValue, setPhoneValue] = useState(defaultNumber);
  const [error, setError] = useState("");
  const isMobile = useMediaQuery();
  const history = useHistory();
  const confirmationMsg =
    "Your reminder and link will be sent on the day of the show!";

  const handleSubmit = async () => {
    if (!!phoneValue && phoneValue.length >= 10) {
      setLoading(true);
      try {
        const authRemind = firebase.functions().httpsCallable("auth-remind");
        authRemind({
          token: userToken,
          phone: phoneValue,
          eventId: process.env.REACT_APP_EVENT_ID,
        }).then(({ data }) => {
          if (data.success) {
            setLoading(false);
            setCookie("reminderSent", true, { maxAge: 31622400 });
            history.push(
              `/?token=${encodeURIComponent(decodeURIComponent(userToken))}`
            );
            displayMessage({
              color: "green",
              children: confirmationMsg,
              timeout: 20000,
            });
          } else {
            setLoading(false);
            setError("There was an issue with your request. Please try again.");
          }
        });
      } catch (error) {
        setLoading(false);
        setError(error.message);
      }
    } else {
      setError("You must enter a valid phone number to receive a reminder.");
    }
  };

  return (
    <GridColumn>
      {cookies?.reminderSent ? (
        <>{confirmationMsg}</>
      ) : (
        <>
          {showLabel && <StyledLabel>Mobile Phone Number</StyledLabel>}
          <StyledPhone
            phoneNumber={phoneValue}
            onChange={(value) => {
              setPhoneValue(value);
              setError("");
            }}
            error={!!error}
            placeholder="Phone Number"
          />
          <FieldMessage active={!!error} content={error} />
          {showFootnote && (
            <StyledFootnote>
              Text me a reminder 30 minutes prior the show with my ticket link.
              Message and data rates may apply. Consent is not a condition of
              purchase. Text back STOP to cancel.
            </StyledFootnote>
          )}
          <Button disabled={loading} isMobile={isMobile} onClick={handleSubmit}>
            {loading ? (
              <LoaderInline />
            ) : buttonText ? (
              buttonText
            ) : (
              "Text me a reminder!"
            )}
          </Button>
        </>
      )}
    </GridColumn>
  );
};

const StyledLabel = styled(Label)`
  margin-bottom: 4px;
`;

const StyledPhone = styled(PhoneMask)`
  width: 100%;
`;

const StyledFootnote = styled(Footnote)`
  margin: 8px 0 0;
  color: #000;
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
`;

const Button = styled.button`
  margin-top: 24px;
  background-color: #ea1300;
  color: #ffffff;
  text-decoration: none;
  border-radius: 50px;
  padding: 10px 35px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: clamp(18px, 3vw, 24px);
  font-weight: 700;
  outline: 0;
  border: 0;
  align-self: start;

  &:hover,
  &:focus {
    background-color: #fff;
    color: #ea1300;
    border: 2px solid #ea1300;
  }
  ${({ isMobile }) =>
    isMobile &&
    `
  align-self: center;
  z-index: 51;
  `}
`;
