import React from "react";
import { RegisterLayout } from "./RegisterLayout";

const Register = ({ onSuccess }) => {
  return <RegisterLayout onSuccess={onSuccess} />;
};

export default Register;
export * from "./Registered";
export * from "./RegisterLayout";
export * from "./RegistrationForm";
