import React from "react";
import styled from "styled-components";
import { fonts } from "theme/fonts";

export const Countdown = ({ label, days, hours, minutes, seconds }) => {
  return (
    <CountdownContainer xCenter>
      <CountdownTitle>{label}</CountdownTitle>
      <GridRow>
        {days >= 1 && (
          <Outline xCenter>
            <Increment>{days}</Increment>
            <Label>Day{days !== 1 ? "s" : ""}</Label>
          </Outline>
        )}
        <Outline xCenter>
          <Increment>{hours}</Increment>
          <Label>Hour{hours !== 1 ? "s" : ""}</Label>
        </Outline>
        <Outline xCenter>
          <Increment>{minutes}</Increment>
          <Label>Minute{minutes !== 1 ? "s" : ""}</Label>
        </Outline>
        {days < 1 && (
          <Outline xCenter>
            <Increment>{seconds}</Increment>
            <Label>Second{seconds !== 1 ? "s" : ""}</Label>
          </Outline>
        )}
      </GridRow>
    </CountdownContainer>
  );
};

const CountdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: 100%;
  margin-bottom: 25px;
`;

const CountdownTitle = styled.h3`
  font-size: 30px;
  margin-bottom: 10px;
  color: #ea1300;
  font-weight: 700;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 58px;
    margin-bottom: 30px;
  }
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  max-width: 900px;
`;

const Increment = styled.div`
  font-weight: 700;
  font-size: 50px;
  line-height: 1.25;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 125px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 30px;
  }
`;

const Outline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  width: 25%;
`;
