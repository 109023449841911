import React from "react";
import styled from "styled-components";

export const CountdownSmall = ({ label, days, hours, minutes, seconds }) => {
  return (
    <>
      <CountdownContainer xCenter>
        <CountdownTitle>{label}</CountdownTitle>
        <GridRow>
          {days >= 1 && (
            <Outline xCenter>
              <Increment>{days}</Increment>
              <Label>Day{days !== 1 ? "s" : ""}</Label>
            </Outline>
          )}
          <Outline xCenter>
            <Increment>{hours}</Increment>
            <Label>Hour{hours !== 1 ? "s" : ""}</Label>
          </Outline>
          <Outline xCenter>
            <Increment>{minutes}</Increment>
            <Label>Minute{minutes !== 1 ? "s" : ""}</Label>
          </Outline>
          {days < 1 && (
            <Outline xCenter>
              <Increment>{seconds}</Increment>
              <Label>Second{seconds !== 1 ? "s" : ""}</Label>
            </Outline>
          )}
        </GridRow>
      </CountdownContainer>
    </>
  );
};

const CountdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    align-items: center;
    width: 180px;
  }
`;

const CountdownTitle = styled.h3`
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: #ea1300;
  margin-left: auto;
  margin-right: auto;
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 22px;
    margin-bottom: 5px;
  }
  @media (min-width: 950px) {
    margin-top: 0px;
    color: #000;
  }
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 900px;
`;

const Increment = styled.p`
  font-weight: 600;
  font-size: 45px;
  line-height: 1.25;
  margin: 0;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 24px;
    line-height: 1;
  }
`;

const Label = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 11px;
    margin: 0;
  }
`;

const Outline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  width: 30%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 10px 5px;
  }
`;
