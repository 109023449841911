import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import {
  firebase,
  EventContext,
  UserContext,
  AnalyticsContext,
} from "Components";
import { useDocumentData } from "react-firebase-hooks/firestore";
import _ from "lodash";
import { useMediaQuery } from "Components/Hooks/useMediaQuery";

export const SSOButton = ({ buttonText, isLogin, ...props }) => {
  const { user } = useContext(UserContext);
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);
  const [link, setLink] = useState();
  const isMobile = useMediaQuery();
  const [publicConfig] = useDocumentData(
    firebase.firestore().doc(`events/${event.id}/config/public`)
  );

  useEffect(() => {
    if (publicConfig) {
      createOauthLink();
    }
  }, [publicConfig]);

  const createOauthLink = () => {
    let oAuthConfig =
      process.env.NODE_ENV === "production"
        ? _.get(publicConfig, "oauth.prod")
        : _.get(publicConfig, "oauth.dev");

    const oAuthUrl = _.get(oAuthConfig, "authUrl");
    const oAuthloginUrl = `${oAuthUrl}${isLogin ? "/login" : "/register"}`;
    const oAuthClientId = _.get(oAuthConfig, "clientId");
    const oAuthRedirectUrl = _.get(oAuthConfig, "redirectUrl");
    const oAuthScope = _.get(oAuthConfig, "scope");

    if (oAuthloginUrl) {
      let url = new URL(oAuthloginUrl);

      const stateParam = [user.uid, event.id].join("|");

      const stateParamEncoded = Buffer.from(stateParam).toString("base64");

      const params = new URLSearchParams([
        ["promo", event.register.ssoPromoCode],
        ["response_type", "code"],
        ["client_id", oAuthClientId],
        ["redirect_uri", oAuthRedirectUrl],
        ["scope", oAuthScope.join("+")],
        ["state", stateParamEncoded],
      ]);

      url.search = params;

      setLink(url);
    }
  };

  const handleSSOClick = async () => {
    const url = link.origin + link.pathname;
    logClick(
      {
        label: "SSO Button",
        url,
      },
      "internal_link"
    );
    await firebase
      .firestore()
      .doc(`events/${event.id}/oauth_logs/${user.uid}`)
      .set(
        {
          out: firebase.firestore.FieldValue.arrayUnion(new Date()),
        },
        {
          merge: true,
        }
      );

    window.location.href = link;
  };

  return (
    <>
      {/* {link && ( */}
      <RegisterButton isMobile={isMobile} onClick={handleSSOClick} {...props}>
        {buttonText ? buttonText : "Register"}
      </RegisterButton>
      {/* )} */}
    </>
  );
};

const RegisterButton = styled.a`
  margin-top: 10px;
  height: 50px;
  width: 225px;
  background-color: #ea1300;
  color: #ffffff;
  text-decoration: none;
  border-radius: 50px;
  padding: 6px 0px 10px 50px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 700;
  outline: 0;
  border: 0;

  &:hover,
  &:focus {
    background-color: #fff;
    color: #ea1300;
    border: 2px solid #ea1300;
  }
  ${({ isMobile }) =>
    isMobile &&
    `
  font-size: 20px;
  margin: 0px auto;
  padding: 10px 20px 10px 50px;
  width: 200px;
  `}
`;
