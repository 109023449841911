import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import { Modal } from "notes";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext, UserContext } from "Components";
import { useMediaQuery } from "Components/Hooks/useMediaQuery";
import { MarkSeriesCanvas } from "react-vis";
import { register } from "serviceWorker";

export const Footer = ({
  showFooter,
  registration,
  registered,
  showFooterTop,
  expandFooterTop,
}) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;
  const isMobile = useMediaQuery();

  let footerLogo = `${api}/o/${storageDirectory}%2F${event?.logos?.footer?.path}?alt=media`;
  let footerTopRepeat = `${api}/o/${storageDirectory}%2Ffooter-top-repeat.png?alt=media`;
  let footerBackground = `${api}/o/${storageDirectory}%2Ffooter-background.png?alt=media`;

  const footerLogoLink = event.logos.footer.link;
  const menu = event.menus.footer;

  const linkClickHandler = () => {
    logClick(
      {
        label: "Footer MAX Links",
        url: "https://www.max.live/",
      },
      "external_link"
    );
  };
  const menuItemClickHandler = (link) => {
    logClick(
      {
        label: `Footer AARP links, ${link}`,
        url: link,
      },
      "external_link"
    );
  };

  return (
    showFooter && (
      <FooterWrapper
        as="footer"
        registration={registration}
        background={footerBackground}
      >
        <FooterContainer isMobile={isMobile}>
          {isMobile ? (
            <>
              <Menu isMobile={isMobile}>
                {menu.map((item, idx) => {
                  return (
                    idx < 3 && (
                      <>
                        <li key={idx}>
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => menuItemClickHandler(item.link)}
                          >
                            {item.text}
                          </a>
                        </li>
                        {idx < 2 && isMobile && <li>|</li>}
                      </>
                    )
                  );
                })}
              </Menu>
              <Menu isMobile={isMobile} column>
                {menu.map((item, idx) => {
                  return (
                    idx < 7 &&
                    idx > 2 && (
                      <>
                        <li key={idx}>
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => menuItemClickHandler(item.link)}
                          >
                            {item.text}
                          </a>
                        </li>
                      </>
                    )
                  );
                })}
              </Menu>

              <Menu isMobile={isMobile}>
                {menu.map((item, idx) => {
                  return (
                    idx > 6 && (
                      <>
                        <li key={idx}>
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => menuItemClickHandler(item.link)}
                          >
                            {item.text}
                          </a>
                        </li>
                        {idx !== 8 && <li>|</li>}
                      </>
                    )
                  );
                })}
              </Menu>
            </>
          ) : (
            <>
              <Menu isMobile={isMobile}>
                {menu.map((item, idx) => {
                  return (
                    idx < 6 && (
                      <>
                        <li key={idx}>
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => menuItemClickHandler(item.link)}
                          >
                            {item.text}
                          </a>
                        </li>
                        {idx !== 5 && <li>|</li>}
                      </>
                    )
                  );
                })}
              </Menu>
              <Menu isMobile={isMobile}>
                {menu.map((item, idx) => {
                  return (
                    idx > 5 && (
                      <>
                        <li key={idx}>
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => menuItemClickHandler(item.link)}
                          >
                            {item.text}
                          </a>
                        </li>
                        {idx !== 8 && <li>|</li>}
                      </>
                    )
                  );
                })}
              </Menu>
            </>
          )}

          <MaxPolicy isMobile={isMobile} onClick={() => linkClickHandler()}>
            {ReactHtmlParser(event.footer.maxPolicy)}. &nbsp;
            <CookieButton
              isMobile={isMobile}
              onClick={() => {
                if (window.Cookiebot) {
                  window.Cookiebot.renew();
                }
              }}
            >
              Click here to set Cookie Preferences.
            </CookieButton>
          </MaxPolicy>
        </FooterContainer>
      </FooterWrapper>
    )
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  color: #ffffff;
  width: 100%;
  background-color: #37383d;
  position: relative;
  z-index: 20;
  font-size: 14px;
  a,
  a:visited {
    color: #ffffff;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  gap: ${({ isMobile }) => !isMobile && "15px"};
  padding: 35px 15px;
  text-align: center;
  background-color: #37383d;
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    display: block;
    padding: 3px 15px 0px;
    line-height: 130%;
    white-space: nowrap;
  }

  a,
  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  ${({ isMobile }) =>
    isMobile &&
    `
  a {
    padding-bottom: 15px;
  }
  `}
  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`;

const MaxPolicy = styled.p`
  cursor: pointer;
  font-size: 13px;
  padding-left: 15px;
  text-align: left;
  line-height: 130%;
`;

const CookieButton = styled.button`
  border: none;
  background-color: transparent;
  color: #ffffff;
  display: inline;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin-top: ${({ isMobile }) => isMobile && "15px"};
`;
const CenteredView = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 25px;
`;
