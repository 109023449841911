import React from "react";
import styled from "styled-components";

export const Hero = ({
  heroContent = false,
  heroPadding = "56.25vw",
  videoStyle,
}) => {
  return (
    heroContent && (
      <HeroWrapper id="hero">
        <HeroContainer heroPadding={heroPadding}>
          <HeroContent style={videoStyle}>{heroContent}</HeroContent>
        </HeroContainer>
      </HeroWrapper>
    )
  );
};

const HeroWrapper = styled.div`
  background: #ffffff;
  display: flex;
`;

const HeroContainer = styled.div`
  height: ${(props) => (props.heroPadding ? props.heroPadding : 0)};
  max-height: 600px;
  position: relative;
  width: 100%;
  display: flex;
`;

const HeroContent = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }

  .vimeo {
    width: 100%;
  }
`;
