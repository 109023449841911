import React, { useContext, useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { ChatContext } from "Components";
import { ButtonPrimary, Icon } from "notes";

export const ChatPanel = () => {
  const { messages, username } = useContext(ChatContext);
  const [showScroll, setShowScroll] = useState(false);
  const chatPanelRef = useRef();

  useEffect(() => {
    chatPanelRef.current.addEventListener("scroll", handleScroll);
    return () => {
      chatPanelRef.current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (e) => {
    setShowScroll(e.target.scrollTop < 0);
  };

  const scrollToEnd = () => {
    chatPanelRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <ChatPanelWrapper username={username} ref={chatPanelRef}>
      {messages.docs.reverse().map((m) => {
        const { username, message } = m.data();
        return (
          <div className="ChatPanel__MessageBox" key={m.id}>
            <p className="ChatPanel__Username">{username}</p>
            <p className="ChatPanel__Message">{message}</p>
          </div>
        );
      })}
      {showScroll && (
        <StyledButtonPrimary
          style={{
            position: "absolute",
            right: "15px",
            marginBottom: "15px",
          }}
          onClick={scrollToEnd}
        >
          Back to Bottom
          <StyledIcon
            name="Down"
            style={{
              width: "15px",
              height: "15px",
              marginTop: "-1px",
              marginLeft: "2px",
            }}
          />
        </StyledButtonPrimary>
      )}
    </ChatPanelWrapper>
  );
};

const StyledIcon = styled(Icon)`
  width: 33px;
  height: 33px;
  margin-top: -9px;

  path {
    fill: #fff;
  }
`;

const StyledButtonPrimary = styled(ButtonPrimary)`
  position: absolute;
  right: 15px;
  background: rgba(0, 0, 0, 0.25);
  min-width: unset;
  font-size: 12px;

  :hover,
  :focus {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const ChatPanelWrapper = styled.div`
  padding: 0 15px;
  color: #000000;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-flow: column-reverse nowrap;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  p {
    margin-top: 0;
  }

  .ChatPanel__MessageBox {
    word-break: break-word;
  }

  .ChatPanel__Username {
    color: #737373;
    font-size: 14px;
    margin-bottom: 0;
  }

  .ChatPanel__Message {
    font-size: 17px;
  }
`;
