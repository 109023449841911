import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Video, VideoChat, Heartbeater } from "Components";
import { Layout, BodySection, Container } from "../Layout";
import { AuthAccess } from "services";
import styled, { css } from "styled-components";
import { useMediaQuery } from "Components/Hooks/useMediaQuery";

export const Show = ({ event, token }) => {
  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;

  const isMobile = useMediaQuery();
  const storageDirectory = event.storageDirectory;
  let patternRepeat = `${api}/o/${storageDirectory}%2Ffooter-pattern.png?alt=media`;
  let patternRepeatMobile = `${api}/o/${storageDirectory}%2Fmobile-pattern.png?alt=media`;

  return (
    <>
      <Layout
        // header
        showHeader
        // hero
        heroContent={
          event?.show?.chatActive ? (
            <VideoChat
              videoId={event?.show?.videoId}
              chatId={event?.show?.chatId}
              videoMeta={false}
            />
          ) : (
            <Video id={event?.show?.videoId} />
          )
        }
        videoStyle={
          event?.show?.chatActive
            ? { position: "relative", width: "100%" }
            : undefined
        }
        // heading
        heading={event?.show?.heading}
        // main
        subHeading={false}
        // noPadding
        // footer
        showFooter
      >
        <BodySection>
          <Container>
            <p>{ReactHtmlParser(event?.show?.body)}</p>
          </Container>
        </BodySection>
        <BackgroundPattern isMobile={isMobile}>
          <img
            src={isMobile ? patternRepeatMobile : patternRepeat}
            alt="aarp brain health pattern"
          />
        </BackgroundPattern>
      </Layout>
      <AuthAccess token={token} />
    </>
  );
};
const BackgroundPattern = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  img {
    margin-bottom: -15px;
    object-fit: cover;
    width: 100%;
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      img {
        margin-bottom: -2px;
        height: unset;
        width: 170%;
      }
    `}
`;
