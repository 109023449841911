import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { useMediaQuery } from "Components/Hooks/useMediaQuery";

export const Heading = ({ heading = false }) => {
  const isMobile = useMediaQuery();

  return (
    heading && (
      <HeadingWrapper id="heading">
        <HeadingContainer>
          <HeadingContent isMobile={isMobile}>
            {ReactHtmlParser(heading)}
          </HeadingContent>
        </HeadingContainer>
      </HeadingWrapper>
    )
  );
};

// ======== HEADING ======== //

const HeadingWrapper = styled.div`
  background: #ec1300;
`;

const HeadingContainer = styled.div`
  padding: 25px 15px;
`;

const HeadingContent = styled.h3`
  color: #ffffff;
  font-size: clamp(20px, 2.5vw, 30px);
  text-align: center;
  margin: 0;
  font-weight: 400;
  ${({ isMobile }) =>
    isMobile &&
    `
  font-size: clamp(17px, 2vw, 28px);
  padding: 0 30px;
  `}
`;
