import React, { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "Components";

export const Header = ({ showHeader, registration, preCampaign }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.storageDirectory;

  let redLogo = `${api}/o/${storageDirectory}%2F${event?.logos?.header?.path}?alt=media`;
  let whiteLogo = `${api}/o/${storageDirectory}%2F${event?.logos?.footer?.path}?alt=media`;
  let headerBackground = `${api}/o/${storageDirectory}%2F${event?.backgrounds?.header}?alt=media`;

  const headerLogoLink = event.logos.header.link;

  const logoClickHandler = () => {
    logClick(
      {
        label: "Header Logo",
        url: headerLogoLink,
      },
      "external_link"
    );
  };

  return (
    <>
      {showHeader && (
        <HeaderWrapper
          as="header"
          registration={registration}
          background={headerBackground}
          preCampaign={preCampaign}
        >
          <HeaderContainer preCampaign={preCampaign}>
            <Link
              href={headerLogoLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={logoClickHandler}
            >
              <Logo src={whiteLogo} />
            </Link>
          </HeaderContainer>
        </HeaderWrapper>
      )}
    </>
  );
};

const HeaderWrapper = styled.header`
  z-index: 1;
  display: flex;
  flex-direction: row;
  background-color: #ea1300;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 15px;
`;

const Link = styled.a``;

const Logo = styled.img`
  width: 100%;
  max-width: 150px;
`;
