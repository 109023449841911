import React, { useState, useReducer, useContext } from "react";
import { useCookies } from "react-cookie";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { GridColumn, GridRow, Label } from "notes";
import {
  Field,
  FormMessage,
  handleErrors,
  firebase,
  EventContext,
  AnalyticsContext,
} from "Components";
import { reducer } from "Modules";

export const RegistrationForm = ({
  formPart,
  formData,
  formLegal,
  onSuccess,
  submitButton,
}) => {
  const [registration, setRegistration] = useReducer(reducer, {});
  const [errors, setErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { event } = useContext(EventContext);
  const { logLeadSubmission, setUserProperties } = useContext(AnalyticsContext);
  const [cookies, setCookie] = useCookies();

  const { fields, ageGateMessage } = formData;
  const formHeading = event.register.formHeading;

  const handleChange = (value, name) => {
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  const handleSubmit = async () => {
    let errorObject = handleErrors(fields, registration, formPart);
    if (!errorObject) {
      setSubmitting(true);
      try {
        firebase
          .firestore()
          .collection(`events/${process.env.REACT_APP_EVENT_ID}/lead_submits`)
          .add({
            ...registration,
            email: cookies.email ?? registration.EMAIL,
          })
          .then((ref) => {
            ref.onSnapshot((doc) => {
              const { token } = doc.data();
              if (token) {
                onSuccess(
                  false,
                  token,
                  formPart,
                  cookies.email ?? registration.EMAIL
                );
                setSubmitting(false);
                logLeadSubmission({ formPart });
                setUserProperties({ token });
              }
            });
          });
      } catch (error) {
        setSubmitting(false);
      }
    } else {
      setErrors(errorObject);
    }
  };

  return (
    <>
      {formHeading && <FormTitle>{formHeading}</FormTitle>}

      <FormFields>
        {fields &&
          fields
            .sort((a, b) => a.order - b.order)
            .filter((field) => {
              if (formPart === 1) {
                return field.formPart === "1";
              } else if (formPart === 2) {
                return field.formPart === "2";
              }
              return field;
            })
            .map((field, index) => {
              return (
                <FieldContainer fullSize={field.span === "full"} key={index}>
                  {field.type !== "optIn" && !!field.label && (
                    <Label>{field.label}</Label>
                  )}
                  <Field
                    field={field}
                    fieldCheck={
                      !!field.requires ? registration[field.requires] : true
                    }
                    value={registration[field.name]}
                    onChange={(value) => handleChange(value, field.name)}
                    error={{
                      status: errors[field.name],
                      message: "This is a required field and cannot be blank.",
                    }}
                  />
                </FieldContainer>
              );
            })}
      </FormFields>

      <FormMessage
        active={!!errors?.AGE_GATE}
        content={ageGateMessage}
        style={{ order: 6 }}
      />

      {formLegal && <FormLegal>{ReactHtmlParser(formLegal)}</FormLegal>}

      {submitting ? (
        <RegisterButton disabled>Loading</RegisterButton>
      ) : (
        <RegisterButton onClick={handleSubmit}>
          {submitButton ? submitButton : "Submit"}
        </RegisterButton>
      )}
    </>
  );
};

const FormTitle = styled.h3`
  color: #ffffff;
`;

const RegisterButton = styled.button`
  margin-top: 24px;
  background-color: #ea1300;
  color: #ffffff;
  text-decoration: none;
  border-radius: 50px;
  padding: 10px 35px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: clamp(18px, 3vw, 24px);
  font-weight: 700;
  outline: 0;
  border: 0;

  &:hover,
  &:focus {
    background-color: #b91304;
  }
`;

const FormLegal = styled.p`
  font-size: 10px;
  color: #626466;
  margin: 16px 0;

  a {
    color: #626466;
  }
`;

const FormFields = styled(GridRow)`
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
`;

const FieldContainer = styled(GridColumn)`
  flex: 0 1 ${(props) => (props.fullSize ? "100%" : "50%")};
  padding: 8px 4px 0 4px;
  width: ${(props) => (props.fullSize ? "100%" : "50%")};
`;
