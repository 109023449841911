import React, { useContext, useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { EventContext, TimeContext, firebase } from "Components";
import { PreShow } from "./PreShow";
import { Show } from "./Show";
import { PostShow } from "./PostShow";
import Register, { Registered } from "./Register";
import { AdminControls } from "./AdminControls";
import { Preview } from "./Preview";
import { PostCampaign } from "./PostCampaign";
import { PreCampaign } from "./PreCampaign";
import { Loader } from "Components/Loader";

const adminViews = [
  "precampaign",
  "preview",
  "register",
  "registered",
  "preshow",
  "show",
  "postshow",
  "postcampaign",
];

export const Root = () => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const params = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies();
  const [adminView, setAdminView] = useState(null);

  const handleRegister = useCallback(
    (isAdmin, token, formPart = null) => {
      setCookie("token", token, { maxAge: 31622400 });
      if (isAdmin) {
        setCookie("admin", true, { maxAge: 31622400 });
      } else {
        setCookie("formPart", parseInt(formPart) + 1, { maxAge: 31622400 });
      }
    },
    [setCookie]
  );

  useEffect(() => {
    (async () => {
      if (params.get("token") && !cookies.token) {
        const verify = firebase.functions().httpsCallable("auth-verify");
        try {
          const verified = await verify({
            eventId: process.env.REACT_APP_EVENT_ID,
            token: params.get("token"),
          });
          if (verified?.data?.success) {
            handleRegister(verified?.data?.admin, params.get("token"), 1);
          }
        } catch (error) {
          console.error("token validation failed");
        }
      }
      setLoading(false);
    })();
  }, []);

  if (loading) return <Loader active color="red" />;

  if (!event.preview) {
    delete adminViews[1];
  }

  const getAdminView = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const viewHistory = urlParams.get("view");

    switch (viewHistory || adminView) {
      case "precampaign":
        return <PreCampaign />;
      case "register":
        return <Register onSuccess={handleRegister} />;
      case "registered":
        return <Registered />;
      case "preshow":
        return <PreShow event={event} />;
      case "show":
        return <Show event={event} token={cookies.token} />;
      case "postshow":
        return <PostShow event={event} token={cookies.token} />;
      case "preview":
        return <Preview event={event.preview} />;
      case "postcampaign":
        return <PostCampaign />;
      default:
        return null;
    }
  };

  if (cookies?.admin) {
    return (
      <>
        <AdminControls
          views={adminViews}
          selectedView={adminView}
          setAdminView={setAdminView}
        />
        {getAdminView()}
      </>
    );
  }

  const preshowTransitionMinutes = event.preshowTransitionMinutes || 30;

  if (event.campaignStart > time) return <PreCampaign />;
  if (event.campaignEnd < time) return <PostCampaign />;

  if (event.end < time && event.campaignEnd > time) {
    return <PostShow event={event} token={cookies.token} />;
  }

  if (cookies?.formPart == 2) {
    // registered user
    if (time < event.countdown || event.campaignEnd < time) {
      return <Registered />;
    }

    if (
      event.countdown > time &&
      !!event.preview &&
      event.start < time.plus({ minutes: preshowTransitionMinutes })
    ) {
      return <Preview event={event.preview} />;
    }

    if (
      event.start > time.plus({ minutes: preshowTransitionMinutes }) &&
      time < event.start
    ) {
      return <PreShow event={event} />;
    }

    return <Show event={event} token={cookies.token} />;
  }

  return <Register onSuccess={handleRegister} />;
};
