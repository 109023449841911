import { useEffect, useContext } from "react";
import { firebase, AnalyticsContext } from "Components";

export const AuthAccess = ({ token }) => {
  const { logHeartbeat } = useContext(AnalyticsContext);
  const ms = 15000;
  useEffect(() => {
    const interval = setInterval(() => {
      if (token) {
        const access = firebase.functions().httpsCallable("auth-access");
        access({
          token: token,
          eventId: process.env.REACT_APP_EVENT_ID,
        }).catch((err) => console.error("error", err));
        logHeartbeat({
          duration: ms,
          token,
        });
      }
    }, ms);
    return () => clearInterval(interval);
  }, []);

  return null;
};
