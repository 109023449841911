import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Layout, BodySection, Container } from "../Layout";
import styled, { css } from "styled-components";
import { PostShowSurvey, Hero, Organization } from "Components";
import { useMediaQuery } from "Components/Hooks/useMediaQuery";

export const PostShow = ({ event, token, onSuccess }) => {
  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;

  const isMobile = useMediaQuery();
  const storageDirectory = event.storageDirectory;
  let patternRepeat = `${api}/o/${storageDirectory}%2Ffooter-pattern.png?alt=media`;
  let patternRepeatMobile = `${api}/o/${storageDirectory}%2Fmobile-pattern.png?alt=media`;

  return (
    <StyledLayout
      // header
      showHeader
      // hero
      heroContent={
        <HeroWrapper>
          <HeroLeft token={token} showSurvey={event.postShow?.showSurvey}>
            <Hero
              heroContent={
                <HeroSection token={token}>
                  <ThankYouHeader>
                    {token
                      ? event?.postShow?.thankYouHeading
                      : event?.postShow?.heading}
                  </ThankYouHeader>
                  <ThankYouBody>
                    {token && event?.postShow?.thankYouBody}
                  </ThankYouBody>
                </HeroSection>
              }
            />
          </HeroLeft>

          {event.postShow?.showSurvey && token && (
            <HeroRight>
              <SurveyContainer>
                <PostShowSurvey
                  fields={event?.register?.fields}
                  token={token}
                />
              </SurveyContainer>
            </HeroRight>
          )}
        </HeroWrapper>
      }
      videoStyle={
        event?.show?.chatActive
          ? { position: "relative", width: "100%" }
          : undefined
      }
      // heading
      heading={
        token
          ? event?.postShow?.thankYouSubHeading
          : event?.postShow?.subHeading
      }
      // main
      subHeading={false}
      // noPadding
      // footer
      showFooter
    >
      <BodySection>
        <Container>
          <p>{ReactHtmlParser(event?.postShow?.body)}</p>
        </Container>
      </BodySection>
      <BackgroundPattern isMobile={isMobile}>
        <img
          src={isMobile ? patternRepeatMobile : patternRepeat}
          alt="aarp black history month pattern"
        />
      </BackgroundPattern>
    </StyledLayout>
  );
};
const StyledLayout = styled(Layout)`
  position: relative;
`;
const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #102b4e;
  position: relative;

  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;

const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.token &&
    props.showSurvey &&
    `@media ${props.theme.maxMedia.medium} {
        margin-right: 392px;
      }`}
`;

const HeroRight = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-top: 1px solid #18436b;

  @media ${(props) => props.theme.maxMedia.medium} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 392px;
    flex-shrink: 0;
  }
`;

const SurveyContainer = styled.div`
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const HeroSection = styled.div`
  text-align: center;
  padding: 15px;
`;

const ThankYouHeader = styled.h1`
  font-size: clamp(26px, 7vw, 85px);
  font-family: ${(props) => props.theme.fontStacks.header};
  line-height: 1;
  margin-bottom: 2vw;
  color: #ec1300;
`;

const ThankYouBody = styled.h4`
  font-size: 4vw;
  color: #ffffff;
  margin: 0;
  text-transform: uppercase;

  a {
    color: #ffffff;
    text-decoration: none;
  }

  @media ${(props) => props.theme.maxMedia.medium} {
    font-size: 2.5vw;
  }
`;
const BackgroundPattern = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  img {
    margin-bottom: -15px;
    object-fit: cover;
    width: 100%;
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      img {
        margin-bottom: -5px;
        height: unset;
        width: 170%;
      }
    `}
`;
