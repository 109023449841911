import React, { useContext } from "react";
import styled from "styled-components";
import { Header, Heading, Main, Footer, EventContext } from "Components";

export const Layout = ({
  // header
  registration,
  showHeader,
  preCampaign,
  registered,
  // hero
  heroContent,
  // heading
  heading,
  // main
  noPadding,
  subHeading,
  children,
  // site footer
  showFooter,
  showFooterTop,
  expandFooterTop,
}) => {
  const { event } = useContext(EventContext);

  return (
    <SiteContainer>
      {event.test && <TestEventBanner>Viewing Test Event</TestEventBanner>}

      <Header
        showHeader={showHeader}
        registration={registration}
        preCampaign={preCampaign}
      />

      {heroContent}

      <Heading heading={heading} />

      <Main noPadding={noPadding} subHeading={subHeading} children={children} />

      <Footer
        showFooter={showFooter}
        registration={registration}
        registered={registered}
        showFooterTop={showFooterTop}
        expandFooterTop={expandFooterTop}
      />
    </SiteContainer>
  );
};

// ======== SITE CONTAINER ======== //

const SiteContainer = styled.div`
  flex-direction: column;
  margin: 0 auto;
  max-width: 1450px;
  min-height: 100vh;
  position: relative;
  width: 100%;
  display: flex;

  @media ${(props) => props.theme.maxMedia.large} {
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.65);
  }
`;

const TestEventBanner = styled.div`
  background-color: #ff003b;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  padding: 3px 10px;
`;

// ======== VIEW TEMPLATE ======== //

export const BodySection = styled.div`
  font-size: 18px;
  color: #000000;
  padding: 25px 15px 15px;
  text-align: center;

  p {
    font-weight: 300;
    margin-top: 0;
  }

  a,
  a:visited {
    font-weight: 600;
    color: #000000;
  }
`;

export const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px 10px;
  p {
    font-weight: 400;
    span {
      font-style: italic;
    }
  }
  a {
    color: #ec1300 !important;
  }
`;
